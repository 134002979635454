// #9d4edd
// #822faf

// #c77dff;
// #7b2cbf
$colorPrimaryPurple: #9d4edd;
$colorSecondaryPurple: #822faf;
$colorSecondaryLighterPurple: #c77dff;
$colorSecondaryDarkerPurple: #7b2cbf;
$colorPurpleDark: #5d2191;
$colorPurpleDarker: /*#1b092a*/ #0e0415;
$colorLighterGrey: #9297a3;
$colorGreyLigtherSecondary: #d9d9d9;
$color-grey-dark: #777;
$colorWhiteSmoke: #f5f5f5;
$colorDarkerBlue: #0c1322;
$colorWhite: #fff;
$colorPrimaryPurpleDarker: #351353;
$colorGrayDarker: #515b6d;
// old to be removed
$colorAlertBackground: #faebeb;
$colorAlert: #521822;

$colorSilver: silver;


$primaryColor: #0078d4;
$secondaryColorLight: #4680ff;
$secondaryColor: #1a62ff;
$primaryColorDark: #005799;
$AppBackgroundColor: #e8eced; //#f5f5f5; //#E8ECED


$colorPrimaryLighterGrey: #d8d8d8;
$colorGrayDarkerSecondary: #5e626b;
$colorFooterText: #212b3f;
$colorPrimaryDarker: rgb(36, 58, 94);
$colorBackgroundWhiteSmoke: rgb(236, 240, 245);
$colorGrey: #777;
$colorRedLighter: #ff3333;
$colorRed: #ff0000;
$colorGreen: #9ccc65;
$colorDanger: #ff5252;
$colorOrange: #ffaf1a;
$colorSuccesssAlert: #3c763d;
$colorSuccesssAlertbackground: #dff0d8;
$colorSuccesssAlertborder: #d6e9c6;

$ColorNavGrey:#555;


//......................................
// $white: #ffffff;
// $black: #000000;
// $red: #ff5252;
// $red: #ff0020;
// $light_blue: #8ac1eb;
// $blue: #4680ff;
// $dark-blue: #0c1322;
// $grey: #777;
// $light-grey: #ecf0f5;
